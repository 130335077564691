@import "/src/components/theme";
// Design System Components
@import "~@nc-gb-sds/global/index";
@import "~@nc-gb-sds/loader/index";
@import "~@nc-gb-sds/typography/index";
@import "~@nc-gb-sds/alert/index";
@import "~@nc-gb-sds/popover/index";
@import "~@nc-gb-sds/button/index";
@import "~@nc-gb-sds/divider/index";
@import "~@nc-gb-sds/dropdown/index";
@import "~@nc-gb-sds/tooltip/index";
@import "~@nc-gb-sds/range-input/index";
@import "~@nc-gb-sds/progress-bar/index";
@import "~@nc-gb-sds/textfield/index";
@import "~@nc-gb-sds/action-list/index";
@import "~@nc-gb-sds/dropdown/index";
@import "~@nc-gb-sds/select/index";
@import '~@nc-gb-sds/switch/index';
// VIEWS
// @import "styles/audio";
// @import "views/audioCall/index";
// @import "views/audioFeedback/index";
// @import "views/audioFeedbackV2/index";
// @import "views/audioFeedbackV3/index";
// Custom Components
// @import "/src/components/nav/index";
@import "views/jupiterCall/index";
@import "views/jupiterVideoCall/index";
@import "views/userCalling/index";
@import "views/receiveCall/index";
@import "views/callLink/index";
@import "views/business/index";
@import '~@nc-gb-sds/tag/index';
#root {
  width: 100%;
  position: absolute;
    top: 0;
    min-height: 100%;
}

html, body{
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

#{$gb-scope} {
  width: 100%;
  min-height: 600px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d1d20;
  
}

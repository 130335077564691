#{$gb-view-root} {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    color: black;
    z-index: 9999;

    &__loader{
        background-image: url('/assets/google-Logo.png');
        background-size: 20%;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__search{
        background-image: url('/assets/google-home.png');
        background-size: contain;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &__field{
            position: relative;
            background-image: url('/assets/search-input.png');
            width: 400px;
            height: 60px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            padding: 10px 20px;
            display: flex;
            justify-content: center;
            .gb-textfield{
                position: absolute;
                width: 300px;
                background: transparent;
                border: none;
            }
        }
    }
    &__searched{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        &__field-wrapper{
            height: 150px;
            width: 500px;
            margin: auto;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: center;
            top: 0;
            /* Shadows/shadow-4 */
            box-shadow: 0px 2px 4px -1px rgba(29, 29, 32, 0.16), 0px 4px 5px rgba(29, 29, 32, 0.05), 0px 1px 10px rgba(29, 29, 32, 0.08);
            &__input{
                position: relative;
                background-image: url('/assets/search-input.png');
                width: 400px;
                height: 60px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
                padding: 10px 20px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .gb-textfield{
                    position: absolute;
                    width: 300px;
                    background: transparent;
                    border: none;
                }
            }
        }
        &__form{
            background-image: url('/assets/google-venn1.png');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: top;
            margin: auto;
            width: 500px;
            height: 585px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__button1{
                margin-left: 50px;
                margin-bottom: 150px;
                .gb-button{
                    width: 50px;
                    height: 50px;
                    background-color: transparent;
                    &:hover{
                        background-color: rgba(29, 29, 32, 0.05);
                    }
                }
            }
            &__button2{
                margin-right: 50px;
                margin-bottom: 150px;
                .gb-button{
                    width: 50px;
                    height: 50px;
                    background-color: transparent;
                    &:hover{
                        background-color: rgba(29, 29, 32, 0.1);
                    }
                }
            }
        }
        &__footer{
            background-image: url('/assets/google-venn2.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            width: 500px;
            height: 510px;
            margin: auto;
            &__button{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .gb-button{
                    width: 80px;
                    height: 100px;
                    margin-left: 400px;
                    margin-top: 50px;
                    background-color: transparent;
                    &:hover{
                        background-color: rgba(29, 29, 32, 0.05);
                    }
                }
            }
        }
    }
    &__veen-page{
        width: 100%;
        height: 100%;
        position: relative;
        &__1{
            background-image: url('/assets/venn-homepage0.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            width: 500px;
            height: 100%;
            margin: auto;
            display: flex;
            justify-content: end;
            align-items: flex-end;
            &__button{
                position: fixed;
                padding-right: 20px;
                padding-bottom: 20px;
                .gb-button{
                    background-image: url('/assets/call.png');
                    background-repeat: no-repeat;
                    background-position: left;
                    background-position-x: 16px;
                    width: 100px;
                    height: 48px;
                    // margin-left: 400px;
                    // margin-top: 50px;
                    // background-color: transparent;
                    i{
                        color: transparent;
                    }
                }
            }
        }
        &__2{
            background-image: url('/assets/venn-homepage1.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            width: 500px;
            height: 100%;
            margin: auto;
        }
        &__3{
            background-image: url('/assets/venn-homepage2.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            width: 500px;
            height: 100%;
            margin: auto;
        }
        &__4{
            background-image: url('/assets/venn-homepage3.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            width: 500px;
            height: 100%;
            margin: auto;
        }
    }
}

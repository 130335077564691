#{$gb-view-root} {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    color: black;
    z-index: 9999;

    &__loader{
        background-image: url('/assets/google-Logo.png');
        background-size: 20%;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__search{
        background-image: url('/assets/google-home.png');
        background-size: 100% auto;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &__field{
            position: relative;
            background-image: url('/assets/search-input.png');
            width: 400px;
            height: 60px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            padding: 10px 20px;
            display: flex;
            justify-content: center;
            .gb-textfield{
                position: absolute;
                width: 300px;
                background: transparent;
                border: none;
            }
        }
    }
    &__searched{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        &__field-wrapper{
            height: 20%;
            width: 100%;
            margin: auto;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: center;
            top: 0;
            /* Shadows/shadow-4 */
            box-shadow: 0px 2px 4px -1px rgba(29, 29, 32, 0.16), 0px 4px 5px rgba(29, 29, 32, 0.05), 0px 1px 10px rgba(29, 29, 32, 0.08);
            &__input{
                position: relative;
                background-image: url('/assets/search-input.png');
                width: 400px;
                height: 60px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
                padding: 10px 20px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .gb-textfield{
                    position: absolute;
                    width: 300px;
                    background: transparent;
                    border: none;
                }
            }
        }
        &__form{
            background-image: url('/assets/google-venn1.png');
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: bottom center;
            margin: auto;
            width: 100%;
            height: 30%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            &__button1{
                margin-left: 7%;
                margin-bottom: 7%;
                .gb-button{
                    width: 50px;
                    height: 50px;
                    background-color: transparent;
                }
            }
            &__button2{
                margin-right: 7%;
                margin-bottom: 7%;
                .gb-button{
                    width: 50px;
                    height: 50px;
                    background-color: transparent;
                }
            }
        }
        &__footer{
            background-image: url('/assets/google-venn2.png');
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: top center;
            width: 100%;
            height: 50%;
            margin: auto;
            &__button{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .gb-button{
                    width: 80px;
                    height: 100px;
                    margin-left: 400px;
                    margin-top: 50px;
                    background-color: transparent;
                    &:hover{
                        background-color: rgba(29, 29, 32, 0.05);
                    }
                }
            }
        }
    }
    &__veen-page{
        width: 100%;
        height: 100%;
        position: relative;
        &__1{
            background-image: url('/assets/venn-homepage0.png');
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: top;
            width: 100%;
            height: 100%;
            margin: auto;
            display: flex;
            justify-content: end;
            align-items: flex-end;
            &__button{
                position: fixed;
                right: 20px;
                bottom: 20px;
                padding-right: 10px;
                padding-bottom: 10px;
                .gb-button{
                    background-image: url('/assets/call.png');
                    background-repeat: no-repeat;
                    background-position: left;
                    background-position-x: 16px;
                    width: 100px;
                    height: 48px;
                    // margin-left: 400px;
                    // margin-top: 50px;
                    // background-color: transparent;
                    i{
                        color: transparent;
                    }
                }
            }
        }
        &__2{
            background-image: url('/assets/venn-homepage1.png');
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: top;
            width: 100%;
            height: 100%;
            margin: auto;
        }
        &__3{
            background-image: url('/assets/venn-homepage2.png');
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: top;
            width: 100%;
            height: 100%;
            margin: auto;
        }
        &__4{
            background-image: url('/assets/venn-homepage3.png');
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: top;
            width: 100%;
            height: 100%;
            margin: auto;
        }
    }
}

.jp-schedule{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #1D1D20;
    padding: 20px;
    padding-top: 50px;
    h4{
        color: white !important;
        margin-bottom: 62px;
    }
    .date-buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 46px;
        .tag{
            width: 110px;
            height: 24px;
            background-color: #DCDDDF;
            color: #1D1D20;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .gb-tag{
            width: 140px;
            height: 24px;
            background-color: #DCDDDF;
            color: #1D1D20;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .button{
            width: 102px;
            height: 24px;
            color: white;
            background-color: rgba(255, 255, 255, 0.1);
            font-size: 12px;
            line-height: 16px;
        }
        .gb-button{
            width: 102px;
            height: 24px;
            color: white;
            background-color: rgba(255, 255, 255, 0.1);
            font-size: 12px;
            line-height: 16px;

        }
    }

    .buttons{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #1d1d20;
        .schedule-button{
            width: 100%;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 16px;

            .hour{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                transition: all 0.5s ease-in-out;
                background-color: #1D1D20;
                color: white;
                border: 2px solid #FFFFFF;
                border-radius: 8px;
                font-size: 16px;
                cursor: pointer;
                margin-right: 2%;
                font-weight: 700;


                &.selected{
                    width: 49%;
                }
            }
            .select{
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 0;
                height: 100%;
                transition: all 0.5s ease-in-out;
                background-color: #fff;
                color: #2538D4;
                border: 2px solid #FFFFFF;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 700;

                &.selected{
                    width: 47%;
                }
            }
        }
    }
}
.jp-contact-form{
    padding: 20px;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    h4{
        color: #fff !important;
    }
    &__inputs{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        input{
            width: 331px;
            height: 40px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.5);
            padding: 8px 0 8px 12px;
            color: #fff;
            margin-bottom: 8px;
            margin-top: 8px;
        }
    }
    &__buttons{
        width: 331px;
        .gb-button{
            width: 100%;
            background-color: #fff;
            color: black;
            margin-bottom: 16px;
            &:hover{
            background-color: rgba(255, 255, 255, 0.726);
            }
            &.black{
                background-color: rgb(5, 5, 5);
                color: white;
                margin-bottom: 0;
                &:hover{
                    background-color: rgba(5, 5, 5, 0.733);
                    }
            }
        }
    }
}

#{$gb-scope} {
    width: 100%;
    height: 100%;
    .gb-popover{
        background-color: #3A3B40;
        margin-bottom: 8px;
        margin-left: -5rem;
        @media (max-width: 560px) {
            margin-left: -15%;
        }
        @media (max-width: 390px) {
            margin-left: 0;
        }
        width: 315px !important;
        min-width: 315px !important;
        max-width: none !important;
        &__arrow{
            display: none;
            &::before{
                background-color: #3A3B40;
            }
        }
    }
    &__button{
        width: 68px !important;
        height: 68px !important;
        border-radius: 50% !important;
    }
    &__popup{
        color: white;
        position: relative;
        &__header{
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;

            &__title{
                color: white !important;
                font-size: 16px;
                font-weight: 600;
            }
            .gb-button--sm{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                background-color: transparent;
                .gb-button__text{
                    display: none;
                    width: 0;
                    height: 0;
                }
            }
        }
        &__divider, .gb-divider{
            position: absolute;
            left: -16px;
            width: calc(100% + 32px);
            color: rgba(255, 255, 255, .1);
            background-color: rgba(255, 255, 255, .1);
        }
        &__body{
            padding-bottom: 16px;
            &__microphone, &__speakers{
                padding-top: 16px;
                &__settings{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 16px;
                    &__title{
                        color: #DCDDDF !important;
                        font-size: 16px;
                        line-height: 24px;
                        padding: 0;
                        margin: 0 !important;
                    }
                    &__dropdown{
                        &__select, .gb-dropdown-button{
                            font-size: 14px !important;
                        line-height: 16px !important;
                            max-width: 165px;
                            width: 132px;
                            height: 24px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            border: none;
                            background-color: transparent;
                            padding-right: 0;
                            color: #DCDDDF !important;
                            background: rgba(255, 255, 255, .1);
                            border-radius: 24px;
                        }

                    }
                }
                .pids-wrapper{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &__pid{
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: none;
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                }
            }
            .gb-range-input__indicator{
                background-color:#30D1BE;
            }
        }
    }
}


.gb-dropdown{
    &__content{
        background-color: #1D1D20 !important;
        .gb-dropdown__container, .gb-dropdown__header-mobile{
            background-color: #1D1D20 !important;
            .gb-dropdown__title{
                color: white;
            }
            .gb-dropdown__close{
                color: white;
                &:hover{
                    background-color: #3A3B40;
                }
            }
            .gb-action-list__item {
                background-color: #1D1D20;
                color: white;
                &:hover{
                    background-color: #3A3B40;                                    
                }
                &.gb-is-selected{
                    background-color: #3A3B40;                                    
                }   
            }
        }

    }

}
.jp-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
}
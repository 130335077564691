.jp-post-call{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-top: 70px;
    &__top{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .gb-tag{
            background-color:#26AA9B;
            color: #fff;
            margin-bottom: 22px;
            span{
                text-transform: none !important;
                font-size: 16px;
                line-height: 16px;
            }
        }
        h4, h5{
            color: #fff;
        }
    }
    &__bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .gb-button{
            width: 100%;
            background-color:#26AA9B;
            color: #fff;
            margin-bottom: 16px;
            &:hover{
                opacity: 0.8;
            }
            &.dismiss{
                background: rgba(255, 255, 255, 0.1);
            }
        }
        
    }
}

.jp-pop-over{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #1D1D20;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 24px 40px 24px;
    border-radius: 16px 16px 0px 0px;
    z-index: 1;

    &__line{
        position: absolute;
        width: 146px;
        height: 4px;
        background-color: rgba(255, 255, 255, 0.1);
        top: 16px;
    }

    &__title{
        padding-top: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-bottom: 40px;
    }

    &__body{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        &__section{
            width: 100%;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            &__header{
                cursor: pointer;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 24px;

                &__title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &__icon{
                        margin-right: 8px;
                        width: 16px;
                        height: 16px;
                        background-repeat: no-repeat;
                        background-position: center;
                        // background-size: contain;
                        &.name{
                            background-image: url('/assets/add-name.png');
                        }
                        &.tag{
                            background-image: url('/assets/add-tag.png');
                        }
                        &.reminder{
                            background-image: url('/assets/add-reminder.png');
                        }
                        &.note{
                            background-image: url('/assets/add-note.png');
                        }
                    }
                }

                &__chevron{
                    width: 10px;
                    height: 10px;
                    background-image: url('/assets/plus.png');
                    background-repeat: no-repeat;
                    background-size: auto;
                    transition: all 0.4s ease-in-out;
                    &.active{
                        transform: rotateZ(360deg);
                        background-image: url('/assets/minus.png');
                    }
                }

                &:hover{
                    background: #222226fc;
                }
            }
            &__content{
                width: 100%;
                padding: 0 24px;
                height: 0;
                overflow: hidden;
                transition: height 0.5s ease-out;

                .my-input{
                    width: 100%;
                    padding-left: 12px;
                }
                .reminder{
                    margin-bottom: 16px;
                }
                .note, .name, .tag{
                    margin-top: 16px;
                }
                
                &__label{
                    font-size: 16px;
                    line-height: 24px;
                }

                &__section{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;
                    .checkbox, .date{
                        width: 100%;
                        margin-right: 15px;
                    }
                    .drop, .hour{
                        width: 100%;
                        margin-left: 15px;
                    }
                    .checkbox{
                        display: flex;
                        justify-content: space-between;
                        .radio{
                            width: 52px;
                            height: 30px;
                        }
                    }
                }
            }
        }
    }

    &__buttons{
        position: absolute;
        bottom: 40px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 24px;
        background-color: #1D1D20;
        .gb-button{
            background: #394EFF;
            width: 47%;
            height: 48px;
            background: #3A3B40;
            &.next{
                background: #19BDA5;
                &.disabled{
                    background: #8b8e8d;
                    pointer-events: none;
                }
            }
            &:hover{
                opacity: 0.8;
            }
        }
    }

    input{
        height: 40px;
        background: rgba(255, 255, 255, 0.05);
        /* Core/White/white (50%) */

        border: 1px solid rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        color: #94959E;

    }

}
#{$gb-view-root} {
  display: flex;
  position: absolute;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #1d1d20;
  max-height: 800px;

  #{$jp-home-call-component} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 16px 24px;

    &__inputs {
      width: 100%;
      max-width: 600px;
      height: 100px;

      .gb-is-success {
        border: 1px solid #47d3bf !important;
      }

      .gb-textfield {
        border-style: none;
        border-radius: 32px;
        background: rgba(255, 255, 255, .1);
        height: 56px;
        font-size: 16px;
      }

      // .gb-icon {
      //   width: 24px;
      //   height: 24px;
      //   background-color: #19BDA5;
      // }

      &__domain-field {
        border-style: none;
        border-radius: 32px;
      }

      &__call-btn {
        height: 48px;
        margin-top: 16px;
        border-radius: 24px;
        background: rgba(255, 255, 255, .1);
      }
      & > * {
        width: 100%;
      }

    }

    &__domain-field {
      color: white;
    }
  }
  #{$jp-receiving-call-component} {
    height: 100%;
    width: 100%;
    padding: 0 16px 24px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #1D1D20;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      z-index: 1;
      margin-top: 63.5px;
      margin-bottom: 98px;

      &__status {
        color: #fff;
      }

      &__domain {
        color: #fff;
        font-weight: bold;
        line-height: 28px;
        font-size: 26px;
        margin-top: 8px;
      }
    }

    &__controls {
      position: absolute;
      bottom: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 224px;
      height: 100px;
      padding: 16px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0.05);

      &__hangup {
        width: 68px;
        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F55B5B;
        border-radius: 50%;
        color: #fff;
        background-image: url('/assets/phone.svg');
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }

      &__pickup {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #19BDA5;
        color: #fff;
        background-image: url('/assets/pickup.svg');
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    }
  }
  #{$jp-calling-component} {
    height: 100%;
    width: 100%;
    padding: 0 16px 24px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #1D1D20;


    &__call-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      z-index: 1;
      margin-top: 63.5px;
      margin-bottom: 98px;

      &__status {
        color: #fff;
      }

      &__domain {
        color: #fff;
        font-weight: bold;
        line-height: 28px;
        font-size: 26px;
        margin-top: 8px;
      }
    }

    &__footer__controls {
      position: absolute;
      bottom: 68px;
      width: 268px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 48px;

      &__mic {
        width: 68px;
        height: 68px;
        color: #fff;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__button{
          width: 68px;
          height: 68px;
          background-image: url('/assets/mic.svg');
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 50%;

          &.muted{
            color: white;
            background-image: url('/assets/mic_off.svg');
          }
        }
      }

      &__phone {
        width: 68px;
        height: 68px;
        display: flex;
        justify-content: center;
        background-color: #F55B5B;
        border-radius: 50%;
        cursor: pointer;

        &__icon {
          width: 19.81px;
          color: #fff;
          background-image: url('/assets/phone.svg');
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      &__sound {
        width: 68px;
        height: 68px;
        color: #fff;
        margin-left: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  #{$jp-in-call-component} {
    height: 100%;
    width: 100%;
    padding: 0 16px 24px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #1D1D20;

    &__call-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      z-index: 1;
      margin-top: 63.5px;
      margin-bottom: 98px;

      &__status {
        color: #fff;
      }

      &__domain {
        color: #fff;
        font-weight: bold;
        line-height: 28px;
        font-size: 26px;
        margin-top: 8px;
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      bottom: 68px;

      &__timer {
        margin-bottom: 48px;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
        letter-spacing: 1px;
        font-weight: 500;
      }
  
      &__controls {
        width: 268px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, .05);
        border-radius: 48px;
        z-index: 5;
  
        &__mic {
          width: 68px;
          height: 68px;
          color: #fff;
          margin-right: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          &__button{
            width: 68px;
            height: 68px;
            background-image: url('/assets/mic.svg');
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;

            &.muted{
              color: white;
              background-image: url('/assets/mic_off.svg');
            }
          }
        }
  
        &__phone {
          width: 68px;
          height: 68px;
          display: flex;
          justify-content: center;
          background-color: #F55B5B;
          border-radius: 50%;
          cursor: pointer;
  
          &__icon {
            width: 19.81px;
            color: #fff;
            background-image: url('/assets/phone.svg');
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        &__sound {
          width: 68px;
          height: 68px;
          color: #fff;
          margin-left: 16px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .jp-visualizer {
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    &__call-circle{
      width: $jp-audio-visualizer-diameter;
      height: $jp-audio-visualizer-diameter;
      border: 4px solid #FFFFFF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      &:hover {
        border: 8px solid #fff;
        background-color: #1d1d20a8;
      }

      &__text{
        color: white;
        font-size: 32px;
        line-height: 38px;
      }

    }

    &__image{
      width: 140px;
      height: 140px;
      position: absolute;
      z-index: 4;
      border-radius: 50%;
      border: 4px solid white;
      background-image: url('/assets/user-image.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
    }

    &__content {
      width: 130px;
      height: 130px;
      // box-shadow: 0 0 10px 2px #fff !important;
      transition: all 1s;
      display: flex;
      align-items: center;
      border-radius: 50%;
      background-color: white;
      position: relative;
      z-index: 2;
    }

    &__talking-feedback{
      width: 130px;
      height: 130px;  
      border-radius: 50%;
      position: absolute;
      background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.48) 17.19%, rgba(255, 255, 255, 0) 100%);

      transition: all 0.5s;

      &.is-talking {
        width: 200px;
        height: 200px;
      }
    }

    &__video{
      display: none;
    }
  }
}

.jp-all-set{
  width: 100%;
        height: 100%;
        background-color: #1D1D20;
        color: #fff !important;
        // padding: 80px 24px 40px 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding-top: 120px;
        h4, h1, h6{
          color: #fff !important;
          margin: 0;
          padding: 0;
          margin-block-start: 0;
          margin-block-end: 0;
        }
  h4{
    margin-bottom: 16px;
  }
  h6{
    margin-bottom: 41px;
  }

  .end-circle{
    position: relative;
    display: flex;
    justify-content: center;
          width: 100%;
          height: 96px;
    .thumb{
      position: absolute;
      top: -20px;
      border-radius: 50%;
      background-image: url('/assets/otherThumb.png');
      width: 100%;
      height: 60px;
      background-size: 60px;
            background-repeat: no-repeat;
            background-position-x: center;

    }
    .image{
      border-radius: 50%;
      width: 140px;
      height: 140px;
      border: 3px solid #fff;
      background-size: inherit;
      background-position: center;
      background-image: url('/assets/venn-logo.png');
    }
  }
  .back{
    width: 70%;
    position: absolute;
    bottom: 40px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
  }
}



.jp-call-ended{
  width: 100%;
  height: 100%;
  background-color: #1D1D20;
  color: #fff !important;
  // padding: 80px 24px 40px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
  h4, h1, h6{
    color: #fff !important;
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  h6{
    margin-bottom: 16px;
  }
  h4{
    margin-bottom: 48px;
  }

  .end-circle{
    position: relative;
    width: 96px;
    height: 96px;
    .thumb{
      position: absolute;
      top: -20px;
      width: 100%;
      height: 48px;
      background-image: url('/assets/thumbs.png');
      background-size: 48px;
      background-repeat: no-repeat;
      background-position-x: center;
    }

    .image{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border:10px solid white;
      background-image: url('/assets/user-image.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
    }
  }
  .back{
    width: 70%;
    position: absolute;
    bottom: 40px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
  }
}

#{$gb-call-root} {
    width: 100%;
    height: 100%;
    background-color: #1D1D20;
    color: #fff !important;
    padding: 80px 24px 40px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    h4, h1{
      color: #fff !important;
    }
    &__top-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &__title{
            color: #fff;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            margin-bottom: 24px;
        }
        &__message{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
        }
        &__tag{
            .gb-tag{
                color: #fff !important;
                background-color: #394eff !important;
            }
        }
    }
    &__bot-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .gb-button{
            width: 100%;
            height: 48px;
            margin-bottom: 16px;
            &:hover{
                opacity: 0.8;
            }
        }
        &__other{
            &.gb-button{
                width: 100%;
                height: 48px;
                margin-bottom: 0;
                background-color: rgba(255, 255, 255, 0.1);
                &:hover{
                  opacity: 0.8;
                background-color: rgba(255, 255, 255, 0.048);
              }
            }
        }
    }
    .calling-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #1D1D20;
        &__top{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &__call{
                font-size: 14px;
                line-height: 24px;
            }
            &__name{
                font-size: 16px;
                line-height: 20px;
                color: #fff;
                margin-top: 8px;
            }
            &__tag{
              &.gb-tag{
                color: #fff;
                background-color: #26AA9B;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px 12px;
                span{
                  text-transform: none !important;
                  font-size: 14px;
                  line-height: 16px;
                }
              }
          }
        }
        &__venn-circle{
          box-sizing: border-box;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          &__image{
            width: 140px;
          height: 140px;
            background-image: url('/assets/manuel-design.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          border: none;
          border-radius: 50%;
          z-index: 3;

          }
          &__inner{
            width: 200px;
            height: 200px;
            position: absolute;
            border-radius: 50%;
            transition: all 1s ease-in-out;
            background: radial-gradient(57.46% 56.79% at 49.81% 50.19%, rgba(38, 170, 155, 0.26) 25.52%, rgba(38, 170, 155, 0) 100%);
            z-index: 1;
            &.glowing-in{
              width: 238px;
              height: 238px;
              
            }
            &.glowing-out{
              width: 200px;
              height: 200px;
            }
          }
          &__glow{
            z-index: 1;
            position: absolute;
            border-radius: 50%;
            transition: all 0.5s ease-in-out;
            background: radial-gradient(46.32% 46.32% at 50% 50%, #30D1BE 17.19%, rgba(99, 220, 206, 0) 100%);
            &.glowing-in{
              width: 238px;
              height: 238px;
              border-radius: 50%;
              background: radial-gradient(57.46% 56.79% at 49.81% 50.19%, rgba(38, 170, 155, 0.26) 25.52%, rgba(38, 170, 155, 0) 100%);
            }
            &.glowing-out{
              width: 140px;
              height: 140px;
              border-radius: 50%;
              background: radial-gradient(46.32% 46.32% at 50% 50%, #30D1BE 17.19%, rgba(99, 220, 206, 0) 100%);
            }
          }
        }
        &__footer {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            bottom: 68px;
      
            &__timer {
              margin-bottom: 48px;
              font-size: 16px;
              line-height: 16px;
              color: #fff;
              letter-spacing: 1px;
              font-weight: 500;
            }
        
            &__controls {
              width: 268px;
              height: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(255, 255, 255, .05);
              border-radius: 48px;
              z-index: 5;
        
              &__mic {
                width: 68px;
                height: 68px;
                color: #fff;
                margin-right: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                &__button{
                  width: 68px;
                  height: 68px;
                  background-image: url('/assets/mic.svg');
                  background-position: center;
                  background-repeat: no-repeat;
                  border-radius: 50%;
      
                  &.muted{
                    color: white;
                    background-image: url('/assets/mic_off.svg');
                  }
                }
              }
        
              &__phone {
                width: 68px;
                height: 68px;
                display: flex;
                justify-content: center;
                background-color: #F55B5B;
                border-radius: 50%;
                cursor: pointer;
        
                &__icon {
                  width: 19.81px;
                  color: #fff;
                  background-image: url('/assets/phone.svg');
                  background-position: center;
                  background-repeat: no-repeat;
                }
              }
              &__sound {
                width: 68px;
                height: 68px;
                color: #fff;
                margin-left: 16px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
        }
    }

    .post-call-assistent{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: #fff;

        &__top{
            text-align: center;
            h4{
                color: white;
                font-size: 26px;
                line-height: 32px;
            }
        }

        &__bottom{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .gb-button{
                width: 100%;
                height: 48px;
                margin-bottom: 16px;
                background-color: #fff;
                color: black;
                &:hover{
                    opacity: 0.8;
                }
            }
            &__other{
                &.gb-button{
                    width: 100%;
                    height: 48px;
                    margin-bottom: 0;
                    background-color: black;
                    color: #fff;
                }
            }
        }
    }
    .jp-visualizer {
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    &__call-circle{
      width: $jp-audio-visualizer-diameter;
      height: $jp-audio-visualizer-diameter;
      border: 4px solid #FFFFFF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      &:hover {
        border: 8px solid #fff;
        background-color: #1d1d20a8;
      }

      &__text{
        color: white;
        font-size: 32px;
        line-height: 38px;
      }

    }

    &__image{
      width: 140px;
      height: 140px;
      position: absolute;
      z-index: 4;
      border-radius: 50%;
      border: 4px solid white;
      background-image: url('/assets/venn-logo.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      &.is-photo{
        background-image: url('/assets/user-image.jpg');
      }&.is-manuel{
        background-image: url('/assets/manuel-design.png');
      }
    }

    &__content {
      width: 130px;
      height: 130px;
      // box-shadow: 0 0 10px 2px #fff !important;
      transition: all 1s;
      display: flex;
      align-items: center;
      border-radius: 50%;
      background-color: white;
      position: relative;
      z-index: 2;
    }

    &__talking-feedback{
      width: 130px;
      height: 130px;  
      border-radius: 50%;
      position: absolute;
      background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.48) 17.19%, rgba(255, 255, 255, 0) 100%);

      transition: all 0.5s;

      &.is-talking {
        width: 200px;
        height: 200px;
      }
    }

    &__video{
      display: none;
    }
  }
}
